@import '../libs/variables';
@import '../libs/mixins';

.profiles {
  display: flex;
  flex-direction: column;
  height: 100%;
  

  &-wrapper {
    height: 100%;
    @include flex(space-between, stretch);
    gap: 40px;
    overflow: hidden;
  }

  &-list {
    margin-left: -12px;
    padding: 0 12px; 
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 420px;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-full {
    flex: 1;

    &__header {
      @include flex();
      margin-bottom: 40px;

      &-part {
        &:first-child {
          @include flex(flex-start, center);
          gap: 24px;
        }
      }

      &-img {
        width: 80px;
        aspect-ratio: 1/1;
        border-radius: 100%;
        object-fit: cover;
      }

      &-name {
        @include text(26px, 40px, 700, $green);
      }

      svg {
        color: $dark-gray;
        font-size: 32px;
        cursor: pointer;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }

    &__block {
      &-name {
        @include text(16px, 24px, 700, $dark-gray);
      }

      &-info {
        width: 100%;
        padding: 18px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 5px 32px 0px #EAEAEA;

        @include text(16px, 19px, 400, $dark-gray);
      }
    }
  }

  &-item {
    padding: 10px 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 12px 0px rgba($dark-gray, 0.1);
    transition: 0.2s ease;

    @include flex(flex-start, center);
    gap: 20px;

    &__info {
      @include flex(flex-start, center);
      gap: 16px;
    }

    &__name {
      @include text(16px, 24px, 700, $green);
    }

    &__contact {
      @include text(15px, 15px, 400, $dark-gray);
    }

    &__number {
      width: 20px;
      @include text(16px, 24px, 700, $green);
    }

    &__img {
      width: 40px;
      aspect-ratio: 1/1;
      border-radius: 100%;
      object-fit: cover;
    }

    &:hover {
      background: rgba($message, 0.5);
      cursor: pointer;
    }
  }
}