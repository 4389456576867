@import '../libs/variables';
@import '../libs/mixins';

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.offers {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__title {
        @include text(30px, 35px, 700, #000);
    }

    &-grid {
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 50px;
    }

    &-wrapper {
        padding: 36px 12px 12px 6px;
        flex: 1;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {
            background: $bg;
        }

        &::-webkit-scrollbar-thumb {
            background: $green;
            border-radius: 10px;
            border: 3px solid $bg;
        }
    }
}

.offers-item {
    max-width: 500px;
    width: 450px;
    position: relative;
    background: $ghost-white;
    border-radius: 12px;
    box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);
    // box-sizing: border-box;


    &__new {
        position: absolute;
        top: -20px;
        right: 36px;
        padding: 10px 20px;
        border-radius: 6px;
        background: $red;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #fff;
    }
}



.offers-item__btn-list {
    position: absolute;
    top: -30px;
    left: 36px;
    @include flex(flex-start, center);
    gap: 12px;
}

.offers-item__btn-item {
    width: 56px;
    height: 56px;
    background: $green;
    border-radius: 12px;
    box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);
    transition: 0.3s ease;

    @include flex(center, center);

    svg {
        font-size: 24px;
        color: $ghost-white;
    }

    &:hover {
        cursor: pointer;
        background: $green-hover;
    }

    &-active {
        background: $ghost-white;

        svg {
            color: $green;
        }

        &:hover {
            cursor: default;
            background: $ghost-white;
            color: $green;
        }
    }
}

.offers-item__header {
    width: 100%;
    height: 80px;
    background: $green;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    color: #fff;

    &-item {
        display: flex;
        align-items: center;
        gap: 8px;

        &-heading {
            @include text(18px, 24px, 700, $ghost-white);
        }

        &-text {
            @include text(14px, 20px, 500, $ghost-white);
        }

        svg {
            color: $ghost-white;
        }
    }
}

.offers-item__body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 30px;
    padding-top: 20px;
    // box-sizing: border-box;
}

.offers-item__general {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__title {
        @include text(30px, 35px, 700, #000);
    }

}


.offers-infoblock {
    width: fit-content;
    margin-bottom: 10px;

    &-key {
        @include text(14px, 16px, 600, $gray);
        white-space: nowrap;
    }

    &-value {
        @include text(18px, 24px, 700, $dark-gray)
    }

    &-link {
        // position: absolute;
        // bottom: 0;
    }

    &-oneliner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        padding: 5px 0;
        box-sizing: border-box;
        width: fit-content;

        &-key {
            @include text(16px, 14px, 400, $gray)
        }

        &-value {
            @include text(16px, 18px, 600, $dark-gray)
        }



    }
}

.offers-status_btn {
    position: relative;
    right: 15px;
    margin-bottom: 20px;
}


.offers-item__applicants {
    // width: fit-content;
    // padding: 36px 36px 20px;
    // box-sizing: border-box;
    &__title {
        @include text(30px, 35px, 700, #000);
        margin-bottom: 15px;
    }

    &__grid {
        width: 100%;
        // position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 56px 40px;
        padding-top:15px ;
        padding-bottom: 15px;

        &__column{
            box-sizing: border-box;
        }
        &__child{
            width: 100%;
            display: flex;
            gap: 70px;
        }
    }
}

