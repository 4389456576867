@import '../libs/variables';
@import '../libs/mixins';

.applications {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-wrapper {
    padding: 36px 12px 12px 6px;
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar{
      width: 15px;
    }
    
    &::-webkit-scrollbar-track{
      background: $bg;
    }
    
    &::-webkit-scrollbar-thumb{
      background: $green;
      border-radius: 10px;
      border: 3px solid $bg;
    }
  }


  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 40px;
  }

  &-item {
    position: relative;
    max-width: 550px;
    min-height: 300px;
    background: $ghost-white;
    padding: 36px 36px 20px;
    border-radius: 12px;
    box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);

    &__section {
      position: relative;
    }

    &__new {
      position: absolute;
      top: -20px;
      right: 36px;
      padding: 10px 20px;
      border-radius: 6px;
      background: $red;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      color: #fff;
    }

    &__btn {
      &-list {
        position: absolute;
        top: -28px;
        left: 36px;
        @include flex(flex-start, center);
        gap: 12px;
      }

      &-item {
        width: 56px;
        height: 56px;
        background: $green;
        border-radius: 12px;
        box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);
        transition: 0.3s ease;

        @include flex(center, center);

        svg {
          font-size: 24px;
          color: $ghost-white;
        }

        &:hover {
          cursor: pointer;
          background: $green-hover;
        }

        &-active {
          background: $ghost-white;
          
          svg {
            color: $green;
          }

          &:hover {
            cursor: default;
            background: $ghost-white;

            color: $green;
          }
        }
      }
    }

    &__grid {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 56px 40px;
    }

    &__questionnaire {
      grid-gap: 0;
    }

    &__block {
      margin-bottom: 16px;
      &-key {
        @include text(14px, 16px, 600, $gray);
        margin-bottom: 12px;
      }
      
      &-value {
        @include text(18px, 24px, 700, $dark-gray);
        margin-right: 20px;
      }

      &-date {
        @include flex();

        input {
          background: transparent;
        }
      }

      &-btn {
        width: 28px;
        margin-left: 8px;
        aspect-ratio: 1/1;
        background: $green;
        border-radius: 4px;
        color: #fff;
        transition: 0.2s ease;

        &:hover {
          cursor: pointer;
          background: $green-hover;
        }
      }

      &-loader {
        width: 26px;
        height: 26px;
        border: 4px solid $green;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }

      &-boolean {
        @include flex();
        
        &-part {
          &:first-child {
            @include flex(flex-start, center);
            gap: 12px;
          }
          
        }

        &-checkbox {
          width: 20px;
          aspect-ratio: 1/1;
          border-radius: 4px;
          accent-color: $green;
        }

        &-text {
          width: 120px;
        }
      }
    }

    &__line {
      height: 1px;
      background: rgba($gray, 0.4);
      margin-bottom: 16px;
    }

    &__price {
      &-total {
        @include text(18px, 24px, 700, #000);

        span {
          font-size: 22px;
        }
      }
    }

    &__title {
      @include text(28px, 36px, 700, $dark-gray);
      margin-bottom: 16px;
    }
    &__date{
      @include flex(flex-end, center);
      gap: 8px;
      margin-bottom: 16px;

      h6 {
        @include text(14px, 16px, 500, $gray);
      }

      svg {
        color: $gray;
        font-size: 14px;
      }
    }
    &__btn {
      padding: 10px 16px;
      background: #fff;
      border: 1px solid rgba($gray, 0.1);
      border-radius: 8px;
      box-shadow: 0px 0px 7px 0px rgba($gray, 0.4);
      margin-bottom: 16px;
      cursor: pointer;

      @include flex();
      &-part {
        &:first-child {
          h3 {
            @include text(18px, 24px, 700, $dark-gray);
          }

          h6 {
            @include text(16px, 19px, 600, $green);
          }
        }

        &:last-child {
          svg {
            font-size: 18px;
            color: $green;
          }
        }
      }
    }

    &__statuses {
      padding: 16px 12px;
      background: #fff;
      background: #fff;
      border: 1px solid rgba($gray, 0.1);
      border-radius: 12px;
      box-shadow: 0px 0px 5px 0px rgba($gray, 0.2);

      &-item {
        padding: 14px 12px;
        @include text(17px, 17px, 600, $gray);
        cursor: pointer;

        &-active {
          border-radius: 6px;
          background: $green-button;
          color: $green;
        }
      }

      &-line {
        height: 1px;
        background: rgba($gray, 0.2);
      }

      &-btn {
        padding: 14px 40px;
        background: $green-button;
        border-radius: 6px;
        transition: 0.2s ease;
        @include text(16px, 16px, 600, $green);

        &:disabled {
          cursor: not-allowed;
          background: rgba($dark-gray, 0.1);
          color: $dark-gray;
          pointer-events: none;
        }

        &:hover:enabled {
          cursor: pointer;
          background: rgba($green-hover, 0.55);
        }

        &-wrapper {
          margin-top: 16px;
          gap: 16px;
          @include flex(flex-end, center);
        }

        &-loader {
          width: 44px;
          height: 44px;
          border: 5px solid $green;
          border-bottom-color: transparent;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}