.paginator {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0;
    gap: 15px;
}

.paginator__btn {
    width: fit-content;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: #FFF;
    background: $green;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    transition: all 0.2s ease-out;
    &:hover{
        background-color: $green-hover;
        transform: scale(1.05);
    }
}

.pageNumberContainer {
    display: flex;
    height: fit-content;
    gap: 3px;
}

.pageNumber {
    padding: 10px;
    border: 1px solid black;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
        border: 1px solid $green;
        color: $green;
        transform: scale(1.05);
    }

    &.inactive {
        color: gray;
        border: 1px solid gray;
        cursor: default;

        &:hover {
            color: gray;
            border: 1px solid gray;
            transform: scale(1.00);
        }
    }

    &:hover {
        border: 1px solid $green;
        color: $green;
        transform: scale(1.05);
    }
}