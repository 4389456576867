@import '../libs/variables';
@import '../libs/mixins';

.feedback {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-wrapper {
    padding: 36px 12px 12px 0;
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }

  &-item {
    position: relative;
    background: $ghost-white;
    padding: 40px 40px 80px 40px;
    border-radius: 12px;
    box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);


    &__new {
      position: absolute;
      top: -20px;
      left: 40px;
      padding: 10px 20px;
      border-radius: 6px;
      background: $red;

      @include text(16px, 16px, 400, #fff);
    }

    &__block {
      margin-bottom: 15px;
      &:not(:first-child) {
        padding-top: 15px;
        border-top: 1px solid rgba(#000, 0.1);
      }

      &-key {
        @include text(14px, 16px, 600, $gray);
        margin-bottom: 8px;
      }
      
      &-value {
        @include text(18px, 24px, 700, $dark-gray);
      }
    }

    &__date {
      position: absolute;
      top: 24px;
      right: 24px;

      @include flex(flex-start, center);
      gap: 8px;

      h6 {
        @include text(14px, 16px, 500, $gray);
      }

      svg {
        color: $gray;
        font-size: 14px;
      }
    }
  }
}