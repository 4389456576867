@import '../libs/variables';
@import '../libs/mixins';

.iv {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-wrapper {
    padding: 36px 12px 12px 6px;
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar{
      width: 15px;
    }
    
    &::-webkit-scrollbar-track{
      background: $bg;
    }
    
    &::-webkit-scrollbar-thumb{
      background: $green;
      border-radius: 10px;
      border: 3px solid $bg;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 40px;
  }

  &-item {
    margin-left: 28px;
    position: relative;
    background: $ghost-white;
    padding: 36px 36px 20px 56px;
    border-radius: 12px;
    box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);
    
    &__btn {
      &-list {
        position: absolute;
        top: 36px;
        left: -28px;
        @include flex(flex-start, center);
        flex-direction: column;
        gap: 12px;
      }

      &-item {
        width: 56px;
        height: 56px;
        background: $green;
        border-radius: 12px;
        box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);
        transition: 0.3s ease;

        @include flex(center, center);

        svg {
          font-size: 24px;
          color: $ghost-white;
        }

        &:hover {
          cursor: pointer;
          background: $green-hover;
        }

        &-active {
          background: $ghost-white;
          
          svg {
            color: $green;
          }

          &:hover {
            cursor: default;
            background: $ghost-white;

            color: $green;
          }
        }
      }
    }

    &__new {
      position: absolute;
      top: -20px;
      right: 36px;
      padding: 10px 20px;
      border-radius: 6px;
      background: $red;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      color: #fff;
    }

    &__title {
      @include text(28px, 36px, 700, $dark-gray);
      margin-bottom: 32px;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px 40px;
    }

    &__block {
      &-key {
        @include text(14px, 16px, 600, $gray);
        margin-bottom: 12px;
      }
      
      &-value {
        @include text(18px, 24px, 700, $dark-gray);
      }
    }
  }
  
}