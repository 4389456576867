@import '../libs/variables';
@import '../libs/mixins';

.tickets {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-wrapper {

        padding: 36px 12px 12px 6px;
        flex: 1;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {
            background: $bg;
        }

        &::-webkit-scrollbar-thumb {
            background: $green;
            border-radius: 10px;
            border: 3px solid $bg;
        }
    }


    &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 60px 40px;
    }

    &-item {
        position: relative;
        background: $ghost-white;
        border-radius: 12px;
        box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);

        &__header {
            width: 100%;
            height: 80px;
            background: $green;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;
            color: #fff;

            &-item {
                display: flex;
                align-items: center;
                gap: 8px;

                &-heading {
                    @include text(18px, 24px, 700, $ghost-white);
                }

                &-text {
                    @include text(14px, 20px, 500, $ghost-white);
                }

                svg {
                    color: $ghost-white;
                }
            }
        }

        &__title {}

        &__body {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 0 30px;
            padding-top: 20px;

            box-sizing: border-box;

            &__header {
                @include text(30px, 35px, 700, #000);
                margin-bottom: 15px;
            }

            &__block {
                margin-bottom: 15px;
                width: fit-content;

                &-key {
                    @include text(14px, 16px, 400, $gray)
                }

                &-value {
                    @include text(20px, 25px, 600, $dark-gray)
                }

                &-date {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                }

                &__status_btn {
                    position: absolute;
                    right: 15px;
                }
            }

        }



        &__section {
            position: relative;
        }

        &__new {
            position: absolute;
            top: -20px;
            right: 36px;
            padding: 10px 20px;
            border-radius: 6px;
            background: $red;
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            color: #fff;
        }

        &__btn {
            &-list {
                position: absolute;
                top: -30px;
                left: 36px;
                @include flex(flex-start, center);
                gap: 12px;
            }

            &-item {
                width: 56px;
                height: 56px;
                background: $green;
                border-radius: 12px;
                box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);
                transition: 0.3s ease;

                @include flex(center, center);

                svg {
                    font-size: 24px;
                    color: $ghost-white;
                }

                &:hover {
                    cursor: pointer;
                    background: $green-hover;
                }

                &-active {
                    background: $ghost-white;

                    svg {
                        color: $green;
                    }

                    &:hover {
                        cursor: default;
                        background: $ghost-white;

                        color: $green;
                    }
                }
            }
        }

        &__grid {
            position: relative;
            display: flex;
            // grid-template-columns: repeat(2, 1fr);
            // grid-gap: 56px 40px; 
            gap: 10px;
        }

        &__questionnaire {
            grid-gap: 0;
        }

        &__block {
            margin-bottom: 16px;

            &-key {
                @include text(14px, 16px, 600, $gray);
                margin-bottom: 12px;
            }

            &-value {
                @include text(18px, 24px, 700, $dark-gray);
                margin-right: 20px;
            }

            &-date {
                @include flex();

                input {
                    background: transparent;
                }
            }

            &-btn {
                width: 28px;
                margin-left: 8px;
                aspect-ratio: 1/1;
                background: $green;
                border-radius: 4px;
                color: #fff;
                transition: 0.2s ease;

                &:hover {
                    cursor: pointer;
                    background: $green-hover;
                }
            }

            &-loader {
                width: 26px;
                height: 26px;
                border: 4px solid $green;
                border-bottom-color: transparent;
                border-radius: 50%;
                display: inline-block;
                box-sizing: border-box;
                animation: rotation 1s linear infinite;
            }

            &-boolean {
                @include flex();

                &-part {
                    &:first-child {
                        @include flex(flex-start, center);
                        gap: 12px;
                    }

                }

                &-checkbox {
                    width: 20px;
                    aspect-ratio: 1/1;
                    border-radius: 4px;
                    accent-color: $green;
                }

                &-text {
                    width: 120px;
                }
            }
        }

        &__line {
            height: 1px;
            background: rgba($gray, 0.4);
            margin-bottom: 16px;
        }

        &__price {
            &-total {
                @include text(18px, 24px, 700, #000);

                span {
                    font-size: 22px;
                }
            }
        }

        &__title {
            @include text(28px, 36px, 700, $dark-gray);
            margin-bottom: 16px;
        }

        &__date {
            @include flex(flex-end, center);
            gap: 8px;
            margin-bottom: 16px;

            h6 {
                @include text(14px, 16px, 500, $gray);
            }

            svg {
                color: $gray;
                font-size: 14px;
            }
        }

    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}