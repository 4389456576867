@import '../libs/variables';
@import '../libs/mixins';

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.consultations {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__title {
        @include text(40px, 56px, 700, $dark-green);

    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 60px 40px;
    }

    &-wrapper {
        padding: 36px 12px 12px 6px;
        flex: 1;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {
            background: $bg;
        }

        &::-webkit-scrollbar-thumb {
            background: $green;
            border-radius: 10px;
            border: 3px solid $bg;
        }
    }
}

.consultations-item {
    position: relative;
    background: $ghost-white;
    border-radius: 12px;
    box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);
    box-sizing: border-box;


    &__new {
        position: absolute;
        top: -20px;
        right: 36px;
        padding: 10px 20px;
        border-radius: 6px;
        background: $red;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #fff;
    }
}



.consultations-item__btn-list {
    position: absolute;
    top: -30px;
    left: 36px;
    @include flex(flex-start, center);
    gap: 12px;
}

.consultations-item__btn-item {
    width: 56px;
    height: 56px;
    background: $green;
    border-radius: 12px;
    box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.2);
    transition: 0.3s ease;

    @include flex(center, center);

    svg {
        font-size: 24px;
        color: $ghost-white;
    }

    &:hover {
        cursor: pointer;
        background: $green-hover;
    }

    &-active {
        background: $ghost-white;

        svg {
            color: $green;
        }

        &:hover {
            cursor: default;
            background: $ghost-white;
            color: $green;
        }
    }
}

.consultations-item__header {
    width: 100%;
    height: 80px;
    background: $green;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    color: #fff;

    &-item {
        display: flex;
        align-items: center;
        gap: 8px;

        &-heading {
            @include text(18px, 24px, 700, $ghost-white);
        }

        &-text {
            @include text(14px, 20px, 500, $ghost-white);
        }

        svg {
            color: $ghost-white;
        }
    }
}

.consultations-item__body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 30px;
    padding-top: 20px;
    box-sizing: border-box;
}

.consultations-item__general {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__title {
        @include text(30px, 35px, 700, #000);
    }

}


.consultations-infoblock {
    width: fit-content;
    margin-bottom: 10px;

    &-key {
        @include text(14px, 16px, 400, $gray)
    }

    &-value {
        @include text(20px, 25px, 600, $dark-gray)
    }

    &-link {
        // position: absolute;
        // bottom: 0;
    }

    &-oneliner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        padding: 5px 0;
        box-sizing: border-box;
        width: fit-content;

        &-key {
            @include text(16px, 14px, 400, $gray)
        }

        &-value {
            @include text(16px, 18px, 600, $dark-gray)
        }



    }
}

.consultations-status_btn {
    position: relative;
    right: 15px;
    margin-bottom: 20px;
}


.consultations-item__applicant {
    &__title {
        @include text(30px, 35px, 700, #000);
    }

    &__grid {
        padding-top: 10px;
        width: 100%;
        height: fit-content;
        padding-bottom: 30px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;
        align-items: center;
        gap: 10px;
        &__column:nth-child(2) {
            position: relative;
            margin-top: 20px;
        }
    }
}

.consultations__questionnaire__grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    padding: 20px 0;
    column-gap: 10px;
    row-gap: 10px;

    &__item {}
}

.consultations__questionnaire__grid__item {
    display: flex;

}